import React, {useEffect} from "react";
import {ThemeProvider} from "styled-components"
import {DarkTheme, LightTheme} from "../theme";
import useTheme from "../hook/useTheme";

export default function ThemeContext({children}) {

    const {theme} = useTheme()

    useEffect(() => {

        const body = document.body

        if (theme === 'dark') {
            body.style.backgroundColor = DarkTheme.backgroundColor
        } else {
            body.style.backgroundColor = LightTheme.backgroundColor
        }

    }, [theme])

    return (
        <ThemeProvider theme={theme === 'dark' ? LightTheme : DarkTheme}>
            {children}
        </ThemeProvider>
    )
}
