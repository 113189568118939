import React from "react";
import styled from "styled-components"
import Header from "../components/header";
import Footer from "../components/footer";
import {useWindowSize} from "../hook/useWindowSize";
import Feedback from "./components/Feedback";

export default function Home() {
    const {isMobile} = useWindowSize();
    return (
        <Content $isMobile={isMobile}>
            <Header isMobile={isMobile}/>
            <Body $isMobile={isMobile}>
                <Section $isMobile={isMobile}>
                    <DesInfo $isMobile={isMobile}>
                        <h3>MOBILE APP</h3>
                        <Title $isMobile={isMobile}>
                            Simple and secure financial management
                        </Title>
                        <Test $isMobile={isMobile}>The UpWallet mobile app is available on both iOS and Android.Search for popular crypto currencies and start earning rewards immediately.</Test>
                        <BtnBlock $isMobile={isMobile}>
                            <Btn $isMobile={isMobile} href="/" target="_blank"><Image
                                src={require('../assets/google@2x.png')} alt="google"/></Btn>
                            <Btn $isMobile={isMobile} href="/" target="_blank"><Image
                                src={require('../assets/apple@2x.png')} alt="apple"/></Btn>
                        </BtnBlock>
                    </DesInfo>
                    <ShowImage $isMobile={isMobile} src={require('../assets/pic2@2x.png')}/>
                </Section>
                <Feedback isMobile={isMobile}/>
            </Body>
            <Footer isMobile={isMobile}/>
        </Content>
    )
}


const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #000;
  height: ${props => props.$isMobile ? 'auto' : '100%'};
`
const Body = styled.div`
  max-width:1440px;
  min-width:${props => props.$isMobile ? '100%' : '1240px'};
  margin:0 auto;
  flex: 1;
`
const Section = styled.div`
    /* width:100%; */
    display: flex;
    flex-direction:${props => props.$isMobile ? 'column' : 'row'} ;
    justify-content: space-between;
    align-items: center;
    padding:${props => props.$isMobile ? '0 15px' : '0 0 0 0'};
    /* height: 100%; */
    margin-bottom:${props => props.$isMobile ? '45px' : '87px'};
`

const DesInfo = styled.div`
    width:${props => props.$isMobile ? '100%' : '448px'}; 
    
    h3{
        font-weight: 600;
        font-size: 16px;
        color:#F3BE3C;
        line-height: 22px;
    }
`
const Title = styled.div`
    width: 100%;
    height:${props => props.$isMobile ? 'auto' : '234px'};
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: ${props => props.$isMobile ? '20px' : '56px'};
    color: #FFFFFF;
    line-height: ${props => props.$isMobile ? '24px' : '78px'};
    text-align: left;
    font-style: normal;
`

const Test = styled.div`
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: ${props => props.$isMobile ? '14px' : '20px'};
    color: #FFFFFF;
    line-height: 32px;
    text-align: left;
    font-style: normal;
    margin-top:40px;
`
const BtnBlock = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:71px;
    flex-direction:${props => props.$isMobile ? 'column' : 'row'};
`
const Btn = styled.a`
    width: 195px;
    height: 56px;
    margin-top:${props => props.$isMobile ? '10px' : '0'};
`
const Image = styled.img`
    width: 100%;
    height: 100%;
`

const ShowImage = styled.img`
    width:${props => props.$isMobile ? '100%' : '551px'} ; 
    margin-top:${props => props.$isMobile ? '20px' : '0'} ;
`