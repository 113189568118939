import {useContext} from 'react'
import {Context} from '../context/app-state-context'


function useTheme() {
    const {theme, exchangeTheme} = useContext(Context)
    return {theme, exchangeTheme}
}

export default useTheme
