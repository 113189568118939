export const LightTheme = {
    backgroundColor: 'white',
    buttonBackgroundColor: 'pink',
    textColor: 'black'
}

export const DarkTheme = {
    backgroundColor: 'black',
    buttonBackgroundColor: 'blue',
    textColor: 'white'
}
