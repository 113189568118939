import React, {createContext, useCallback, useEffect, useState} from 'react'


export const Context = createContext({})

const AppStateContext = ({children}) => {

    const [userInfo, setUserInfo] = useState({name: 'Jack'})
    const [theme, setTheme] = useState('')


    /*加载用户数据*/
    function loadUserData() {

    }

    /*加载主题数据*/
    function loadThemeData() {
        const localTheme = localStorage.getItem('Theme')
        if (localTheme == null) {
            /*默认设置light*/
            localStorage.setItem('Theme', 'light')
            setTheme('light')
        } else {
            setTheme(localTheme)
        }
    }

    /*切换主题*/
    function exchangeTheme(theme) {
        setTheme(theme)
        localStorage.setItem('Theme', theme)
    }

    /*初始化加载数据*/
    useEffect(() => {

        loadUserData()
        loadThemeData()

    }, [])


    return (
        <Context.Provider value={{
            userInfo,
            setUserInfo,
            theme,
            exchangeTheme,
            refreshUserData: loadUserData,
        }}>
            {children}
        </Context.Provider>
    )
}

export default AppStateContext
