import React from 'react'
import styled from 'styled-components'

export default function TOS() {
    return (
        <Content className='container privacy-policy'>
            <div className='content-wrapper' style={{paddingTop: '0.5rem'}}>
               <h1> Terms of Service</h1>

                <h3>最后更新时间：2024 年 5 月 28 日</h3>

                本条款构成您（“您”、“您的”）与UpWallet（“UpWallet”、“我们”、“我们的”）之间具有法律约束力的协议。您在使用平台提供、通过平台提供或以其他方式向您提供的UpWallet服务时适用本条款。UpWallet服务可能由UpWallet提供，也可能按照本条款、任何产品条款或任何附加条款的规定由任何UpWallet联盟伙伴提供。
                <br/>
                您注册UpWallet账户、访问UpWallet平台和/或使用UpWallet服务即表明，您同意您已经阅读、理解并接受本条款及其中提及的任何附加文件或条款。您承认并同意您将受本条款不时更新及修订后版本约束并遵守其规定。
             <br/>
                如果您未完全理解并接受本条款，请勿注册UpWallet账户，也不要访问或使用平台或任何UpWallet服务。
             <br/>
                风险提示
             <br/>
                数字资产的价值和任何其他资产一样可能会大幅波动，购买、出售、持有或投资数字资产存在遭受重大经济损失的风险。因此，请基于自身财务状况考虑是否适合交易或持有数字资产。
             <br/>
                关于使用UpWallet服务的相关风险，欲知更多请参见《风险提示》（可能不时更新）。请仔细阅读《风险提示》，但《风险提示》并未详尽列出可能出现的所有风险，也未说明该等风险对您个人的影响。
             <br/>
                决定使用UpWallet服务前，请务必充分了解可能遇到的风险。
             <br/>
                UpWallet不是您的经纪商、中间机构、代理商或顾问，并且UpWallet与您使用UpWallet服务开展的任何交易或其他活动之间不存在信托关系，也不负任何义务。UpWallet不提供任何类型的投资或咨询建议。UpWallet向您提供的任何通信或信息，均不旨在提供任何建议且不应理解为提供任何建议。
             <br/>
                您应自行根据个人投资目标、财务状况和风险承受能力，判断任何投资、投资策略或相关交易是否合适，并自行承担任何相关损失或责任。UpWallet未建议您购买、赚取、出售或持有任何数字资产。您决定购买、出售或持有任何数字资产前，应进行尽职调查并咨询财务顾问。UpWallet对您根据我们提供的信息而做出的购买、赚取、出售或持有数字资产的决定，包括您因该等决定而遭受的任何损失，不承担任何责任。
             <br/>
             <br/>
               <h3> 关于UpWallet与您之间达成的协议</h3>

              <h3> 1.	简介</h3>
                1.1.	关于UpWallet。UpWallet集团是以在线数字资产交易平台为核心的生态系统，为用户提供买卖数字资产的交易平台、允许用户存储数字资产的集成式托管解决方案以及其他与数字资产相关的服务。
             <br/>
                1.2.	本条款。您注册UpWallet账户即与UpWallet签订具有法律约束力的协议。本条款适用于您对UpWallet服务的使用并明确了UpWallet是一家什么样的公司、UpWallet如何为您提供服务、本条款如何变更或终止、遇到问题如何解决以及其他重要信息。
             <br/>
                您必须仔细阅读本条款以及其中引用的文档，如有任何不理解的内容请联系我们。
             <br/>
                如果您在使用UpWallet服务中适用任何本地条款，则该本地条款的效力优先于本条款。
             <br/>
                1.3.	附加文件。本条款中提及的很多附加文件同样适用于您对UpWallet服务的使用。这些附件文件包括：
             <br/>
                a.	UpWallet《隐私声明》，该声明条款对我们处理所收集的关于您的个人数据或您提供给我们的任何个人数据进行了规定。使用UpWallet服务，即表示您理解并同意此类处理，并承诺您提供的所有数据都是准确和最新的。
             <br/>
                b.	UpWallet《风险提示》，载明购买、出售、持有或投资数字资产潜在风险的重要信息。
             <br/>
                c.	UpWallet官网的《手续费阶梯结构》页面。
             <br/>
                d.	《产品条款》，载明使用特定UpWallet服务适用的附加条款与条件。
             <br/>
                您承认您受适用于UpWallet服务的任何相关附加条款与条件的约束并同意遵守其规定。
             <br/>
             <br/>
               <h3> 2.	资格</h3>

                2.1.	资格标准。欲获得注册UpWallet账户以及使用UpWallet服务的资格，您必须：
             <br/>
                a.	是具备充分权力、权限和能力的个人、公司、法人、实体或其他组织，可以(1) 访问和使用UpWallet服务；以及(2) 签订本条款并遵守您在本条款中的义务；
             <br/>
                b.	如果您是个人，则应至少年满18周岁；
             <br/>
                c.	如果您是法律实体的员工或代理商并代表其签署本条款，则您必须获得作为其代表的正式授权并使实体因签订本条款而受其约束；
             <br/>
                d.	未被暂停或取消使用UpWallet服务的资格；
             <br/>
                e.	非受限人员；
             <br/>
                f.	当前无UpWallet账户；并且
             <br/>
                g.	位置、注册地、成立地不在下述区域；不是下述区域的公民、居民；也不在下述区域开展业务经营：
             <br/>
                i.	访问或使用UpWallet服务将违反适用法律的规定或导致UpWallet或任何第三方违反任何适用法律的司法管辖区；或者
             <br/>
                ii.	列入《受禁国家/地区清单》的国家/地区。
             <br/>
                2.2.	修改UpWallet资格标准。UpWallet可随时自行决定修改资格标准。UpWallet将尽可能在变更前提前通知您。但我们偶尔可能须在未提前通知您的情况下进行变更。这种情况可能包括：
             <br/>
                a.	根据法律和/或法规变化做出的变更；
             <br/>
                b.	变更符合您的利益；并且/或者
             <br/>
                c.	出于其他合理理由导致来不及通知您。
             <br/>
                对于我们无法提前通知您的情形，我们会在变更完成后尽快通知您。
             <br/>
             <br/>
               <h3> 3.	我们如何联系对方</h3>

                3.1.	您如何联系我们。如需了解更多有关UpWallet的信息，请登录UpWallet官网查阅。如有任何问题、反馈或意见，请登录https://www.upclouds.xyz/zh-CN/chat通过客服团队联系我们。本条款可能会规定用于特定通知的联系方式。这些通知中的地址不受监控。
             <br/>
                3.2.	我们如何联系您。我们将通过您提供的联系方式与您联系。包括可能通过电子邮件、短信或电话与您联系。请务必确保您提供正确和最新的联系方式。如果您的联系方式发生变化，请立即通知我们。否则，如果您未收到我们的信息、通知或其他重要信息，UpWallet不承担任何责任。
             <br/>
             <br/>
               <h3> 4.	UpWallet服务</h3>

                4.1.	具体产品条款。您开设UpWallet账户后，即可按照本条款以及适用于UpWallet各特定服务的产品条款使用UpWallet服务。
             <br/>
                4.2.	集团内服务。您知晓并同意，部分UpWallet服务可能由UpWallet联盟伙伴提供。
             <br/>
             <br/>
               <h3> 5.	聊天服务</h3>

                5.1.	聊天服务的可用性。我们可能在您使用任何UpWallet服务时，随时向您提供交互式在线聊天服务（“聊天服务”）。您使用聊天服务时可能与机器人、聊天机器人或其他非人类互动。在适用法律要求的范围内，我们使用聊天机器人或其他非人类时将予以说明。您通过聊天服务与我们联系即表明，您授权我们监控并保存您的聊天记录。
             <br/>
                5.2.	重要信息。提供聊天服务是为了方便起见，通常旨在促进您了解UpWallet服务。UpWallet聊天服务将根据您的问题或需求，尽最大努力为您提供准确的最新信息。UpWallet在聊天服务中交流的任何内容，均不得视为UpWallet对服务、流程、决策或响应时间达成的法律协定、陈述或保证。您使用聊天服务时与我们共享的任何个人数据，均必须遵守UpWallet《隐私声明》规定的适用隐私政策和通知的规定。
             <br/>
                5.3.	用户聊天。此外，UpWallet可能会向您提供允许您与平台其他用户直接互动的聊天服务（“用户聊天”）。
             <br/>
                5.4.	禁止行为。您不得通过聊天服务或任何用户聊天，发送任何辱骂、诽谤、欺诈或淫秽信息或旨在操纵市场的任何信息，也不得传播违反适用法律的虚假或误导性信息或讯息，否则可能会导致终止聊天服务会话并可能会导致限制您使用UpWallet服务。
             <br/>
             <br/>
              <h3>  6.	手续费</h3>

                6.1.	手续费支付。您同意按照UpWallet官网或通过任何相关产品条款明确的《手续费阶梯结构》支付与使用UpWallet服务相关的所有适用手续费。
             <br/>
                您授权我们根据《手续费阶梯结构》页面规定的计算方法，从您的UpWallet账户中扣除您在本条款项下或任何《产品条款》项下的所有适用手续费、佣金、利息、收费和其他欠付金额。如果您数字欠付UpWallet资金（以某项数字资产计价），而该数字资产的金额不够偿还所欠部分，则UpWallet可从其他数字资产中扣除欠款来支付（在这种情况下，UpWallet会按照平台当前公布的价格或我们确定的商业上合理的其他价格，将您持有的数字资产兑换成以欠付资金计价的数字资产（或等值法币））。如果您UpWallet账户中的数字资产不足，则您承认您在本条款下到期应付的任何金额均为我们可通过合理商业方式确定的该等数量和形式（无论是数字资产还是其他形式）的到期应付债务。
             <br/>
                6.2.	修改UpWallet手续费。UpWallet可根据本条款第18.3条的规定不时调整手续费。
             <br/>
                关于您的UpWallet账户
             <br/>      <br/>
              <h3>  7.	创建UpWallet账户</h3>

                7.1.	开设账户。您必须创建并维护UpWallet账户，才能访问UpWallet服务和平台。个人可以开设UpWallet个人账户，公司、实体或其他组织也可以开设UpWallet企业账户。
             <br/>
                所有UpWallet账户均完全由UpWallet酌情提供。UpWallet保留无理由拒绝任何UpWallet账户申请或限制您可以持有的UpWallet账户数量的权利。
             <br/>
                7.2.	独有权益。您开设UpWallet账户即表明您同意：
             <br/>
                a.	如果您是个人用户，则您仅将UpWallet账户用于自用且不代表任何第三方使用UpWallet账户，但您获得UpWallet事先书面同意的情形除外；以及
             <br/>
                b.	如果您是公司用户，则您的许可用户仅为实现您的利益使用UpWallet企业账户且不代表任何第三方使用UpWallet企业账户，但您获得UpWallet事先书面同意的情形除外。
             <br/>
                您对您UpWallet账户的所有活动负全责。
             <br/>
                7.3.	身份认证。您需要先向我们提供您以及您许可用户（如相关）的相关信息，完成我们的身份认证流程，然后才能获准开立UpWallet账户并访问和使用UpWallet服务。您提供的所有信息必须完整、准确和真实。任何时候该等信息发生变化，您均必须立即更新。
             <br/>
                您授权UpWallet直接或通过第三方进行UpWallet认为必要的调查，核实您和任何许可用户的身份、保护您和/或UpWallet免遭欺诈、洗钱、恐怖融资或其他金融犯罪的侵害、并根据此类调查的结果采取UpWallet认为必要的任何行动。
             <br/>
                您知晓并理解UpWallet进行调查时可能会将您以及任何许可用户的个人数据，披露给身份验证、合规数据记录、资信调查、欺诈防范或金融犯罪机构；并且您知晓并理解这些机构可全面答复UpWallet的询问。
                您可以查看UpWallet《隐私声明》了解UpWallet有关个人数据处理的更多信息。
             <br/>
                7.4.	深入尽职调查。UpWallet还可能要求您提交您自己、公司或许可用户的附加信息、提供额外记录或文档，或者与UpWallet代表面对面会谈，以便遵守UpWallet的深入尽职调查程序。
             <br/>
                7.5.	记录。只要满足《隐私声明》所述的相关目的、实现税务和会计目的的相关法律、遵守反洗钱法或通过其他方式传达给您的其它内容要求如此，那么UpWallet将保留您的个人数据以便您能继续使用UpWallet服务。请查阅UpWallet《隐私声明》了解UpWallet如何收集和使用与UpWallet网站和UpWallet服务使用和履行相关的用户个人数据。
             <br/>
                7.6.	子账户。UpWallet自行决定您是否可以创建并访问UpWallet子账户。开设UpWallet子账户的每个自然人，均必须遵守本条款项下第7条规定的身份认证要求。一个自然人或法人实体只能开设一个UpWallet子账户。
             <br/>
             <br/>
               <h3> 8.	信息请求</h3>

                8.1.	UpWallet何时会请求您提供信息。为遵守任何适用法律、身份认证要求，或检测洗钱、恐怖融资、欺诈或任何其他金融犯罪或出于任何其他正当理由，UpWallet可能会随时要求您提供信息。只要该等信息是实现其预期目的所必需的，您同意向UpWallet提供UpWallet要求提供的任何该等信息，并允许UpWallet在您UpWallet账户的存续期内或适用法律规定的其他期限内保存该等信息记录。
             <br/>
                8.2.	您提供信息后会怎样。您访问UpWallet账户的权限以及您的UpWallet服务交易限制，会根据收集到的您的信息持续变更。如果有理由怀疑您提供的任何信息错误、不真实、过时或不完整，UpWallet可能会向您发送通知要求您更正、删除相关信息，或采取UpWallet认为必要的其他措施，确保您提供的信息真实正确。
             <br/>
                8.3.	如果您未能按要求提供信息。您必须遵守我们发给您的任何信息要求。如果您拒绝按照要求提供信息，或者不及时提供信息，UpWallet保留不经通知立即暂停或终止您访问UpWallet账户或全部或部分UpWallet服务的权利。
             <br/>
             <br/>
               <h3> 9.	访问您的UpWallet账户</h3>

                9.1.	访问。为访问您的UpWallet账户，您或许可用户（相关情况下）必须拥有必要设备（如计算机或智能手机）且能够访问互联网。可通过平台或UpWallet规定的其他访问模式（包括API）直接访问您的UpWallet账户。您只能用UpWallet提供给您或许可用户用于实现该等目的的访问ID来访问您的UpWallet账户。UpWallet可能需要进行多重身份验证来确保您的UpWallet账户安全。
             <br/>
                使用平台和其他访问方式须遵守UpWallet告知您的附加条款。
             <br/>
                9.2.	限制访问第三方。您必须确保以您名义注册的任何UpWallet账户，不会被除您自己之外的任何其他人使用，如果是UpWallet企业账户则不会被您许可用户之外的任何其他人使用，但根据本条款被其他人使用的情形除外。
             <br/>
             <br/>
               <h3> 10.	账户信息和交易记录</h3>
                10.1.	您账户的历史记录。您可通过平台访问账户历史记录。您必须仔细审查账户历史记录。若发现任何不认识或认为不正确的记录或交易，必须在获得账户历史记录之日起14日内告知。
             <br/>
                10.2.	错误。UpWallet可随时纠正您账户历史记录中的任何错误，并有权作废或撤销存在或基于明显错误的任何交易，或修改该等交易明细使其体现无此等明显错误情况下的正确或公平明细。在不损害本条款项下其他条款一般性且无欺诈或恶意的情况下，UpWallet对您由任何原因产生的明显错误所致而遭受或招致的任何性质的直接、间接、特殊或后果性损失（包括但不限于利润损失、机会损失）或要求概不负责，即使您已告知UpWallet发生该等情况的可能性或该等情况可合理预见。
             <br/>
                10.3.	信息共享。根据适用法律要求，UpWallet可能需要向第三方分享您UpWallet账户和账户历史记录的相关信息。您承认并同意UpWallet有权披露该等信息。关于UpWallet如何处理您的个人数据，欲知更多请查阅UpWallet《隐私声明》。
             <br/>
                使用您的账户
             <br/>
             <br/>

              <h3>  11.	指令</h3>

                11.1.	发出指令。您必须确保提交的任何指令均完整准确。UpWallet无须核实任何指令的准确性、真实性或有效性，并且不会因指令重复或看起来重复而监控或拒绝执行指令。但如果我们对指令的准确性、真实性或有效性有疑问，UpWallet可拒绝执行或推迟执行任何指令，或者寻求与该指令有关的进一步信息。
             <br/>
                指令不可撤销，一旦提交，未经UpWallet书面同意，您或许可用户无权撤销或撤回指令。您的指令未经UpWallet服务器接收前，不得视为UpWallet已收到您的指令。UpWallet对所有指令的记录具有最终决定性，且对您具有约束力。
             <br/>
                11.2.	按照您的指令行事。您或许可用户提交指令即表明授权UpWallet在您的UpWallet账户上发起交易。UpWallet因而有权按照您的指令，从您的UpWallet账户中贷记或借记（或向第三方提供结算信息，以便第三方进行贷记或借记）数字资产。如果您UpWallet账户中的数字资产或法币不足以完成交易（即不足以结算交易以及不足以支付与交易相关的所有费用），则UpWallet有权拒绝达成任何交易。您有责任在您的UpWallet账户中持有足够数字资产或法币。
             <br/>
                11.3.	指令保护。您知晓通过平台或电子邮件传输的指令和信息，通常皆通过互联网传输，并且路由路径可能为无特别保护的公共跨国设施。UpWallet不能保证保护传输指令和信息完全不遭未经授权的访问，而您接受与此相关风险。
             <br/>
                11.4.	提现。在遵守本条款和所有适用产品条款的前提下，如果您的UpWallet账户中余额充足，且未持有与任何UpWallet服务相关的数字资产，则您可以通过在平台上提交提现请求，指示UpWallet将数字资产转账至外部钱包地址。收到您的提现请求后，UpWallet将：(i) 扣除您的UpWallet账户余额；以及(ii) 向您指定的外部钱包发起链上转账。如果UpWallet有理由认为，相关提现的执行将违反适用法律，则UpWallet可能不会处理该提现请求。UpWallet也可能在UpWallet认为适当的时间暂停提现，以解决平台上的任何突发事件。此类事件得到解决后，UpWallet将恢复提现。
             <br/>
             <br/>
              <h3>  12.	交易</h3>


                12.1.	开展交易。您可以与UpWallet或其他用户直接交易，这些交易可能由UpWallet也可能非由UpWallet促成。
             <br/>
                UpWallet未陈述或保证任何交易将成功完成或在特定时间内完成。
             <br/>
                12.2.	未经授权的交易。您自行负责控制或使用您的UpWallet账户。因此UpWallet会假设从您UpWallet账户发出的任何指令均是由您或许可用户授权发出，除非UpWallet已得到并非如此的通知。您应监控历史记录，以便发现您UpWallet账户中任何未经授权或可疑的活动并尽快通知我们。UpWallet对执行非授权指令而开展的交易所导致的任何索赔或损失概不负责，但您已根据本条通知UpWallet的情形除外。
             <br/>
                12.3.	保留交易信息。为促进遵守有关数据保留的全球行业标准，您同意允许（而非要求）我们在您UpWallet账户有效期内或在适用法律规定的其他期限内，按照保留交易信息的原始意图保留所有交易信息的记录。请查阅UpWallet《隐私声明》https://www.upclouds.xyz/en/privacy，了解UpWallet如何收集和使用与UpWallet网站和UpWallet服务的使用和履行相关的数据。
             <br/>
             <br/>
               <h3> 13.	重大利益和冲突</h3>

                13.1.	UpWallet集团。您知晓UpWallet集团由若干家开展数字资产相关业务的公司组成，而UpWallet是该集团中的一员。
             <br/>
                13.2.	UpWallet职责的性质。您理解并同意您与UpWallet或是UpWallet向您提供的任何服务之间的关系或是任何其他事项，均不会导致UpWallet或任何UpWallet联盟伙伴产生任何责任，无论是法律责任、公平责任还是信托责任，但本条款项下中明确规定的责任除外。特别是UpWallet以及任何UpWallet联盟伙伴均可能随时以多重身份行事，并且UpWallet以这些身份行事时可能会向多个用户（包括您）收取手续费或佣金。您同意UpWallet以该等身份行事并提供UpWallet任何其他服务，或与您、任何UpWallet联盟伙伴或任何其他用户开展业务，或为您、任何UpWallet联盟伙伴或任何其他用户开展业务。
             <br/>
                13.3.	重大利益。您理解并同意，UpWallet或任何UpWallet联盟伙伴均无须：(1) 仔细考虑UpWallet或任何UpWallet联盟伙伴获悉的具有重大利益关系的任何信息；(2) 向您披露任何该等信息；或(3) 利用该等信息为您的利益服务。您进一步承认UpWallet向您提供UpWallet服务过程中，可能会不时收到通用市场信息，并将其应用于UpWallet日常业务。
             <br/>
                13.4.	利益冲突。UpWallet建立并保持有效的组织和行政安排，旨在采取所有适当措施识别并管理UpWallet与UpWallet用户以及相关第三方之间的利益冲突，防止利益冲突对UpWallet用户的利益造成不利影响。如果该等组织和行政安排不足以防范损害您利益的风险，则UpWallet将告知您相关利益冲突的性质和/或来源，以及为降低该等风险已然采取的措施，以便您做出明智决定是否继续与我们开展交易。UpWallet无法以任何其他方式管理利益冲突时，始终保留拒绝为您的利益行事的权利。
             <br/>
             <br/>
               <h3> 14.	交易限制</h3>

                14.1.	您的交易限制。您的UpWallet账户可能会在以下方面受到限制：
             <br/>
                a.	您UpWallet账户可进行的交易金额或数量；以及/或者
             <br/>
                b.	您可以转入或转出您UpWallet账户的法币或数字资产的金额或价值，
             <br/>
                以上每种情形均限定在给定期限内（例如每天）。所有适用限制均将在您的UpWallet账户中显示。
             <br/>
                14.2.	更改您的交易限制。UpWallet保留根据UpWallet绝对酌情权随时更改适用于您UpWallet账户的任何交易限制的权利。您也可以请求变更对您的限制。任何变更均由UpWallet全权决定，并受UpWallet认为必要的任何进一步条件约束。
             <br/>
             <br/>
               <h3> 15.	支持的数字资产</h3>

                15.1.	支持的数字资产。UpWallet服务仅适用于支持的数字资产，这些资产可能会不时更改。UpWallet已将支持的数字资产列表发布在官网上。UpWallet可以从该支持的数字资产列表中移除或暂停一种或多种数字资产，并将尽合理的商业努力提前通知到您，这意味着，您将无法再访问作为UpWallet服务一部分的此类数字资产，只能从您的UpWallet账户中将其提现。如果您在指定期限内未将此类不再支持的数字资产提走，则UpWallet可酌情将其兑换为另一种类型的数字资产，即稳定币。UpWallet应在进行此类兑换前通知到您，您可以在UpWallet规定的合理期限内提取此类数字资产，然后再将其兑换为稳定币。
             <br/>
                如本条所述，对于使用您的UpWallet账户购买我们不支持的数字资产，或将此类不支持的数字资产兑换为其他类型的数字资产，UpWallet概不负责。
             <br/>
                15.2.	分叉。我们决定是否支持某分叉时，可能会暂时中止与特定数字资产相关的任何UpWallet服务。我们无义务支持您UpWallet账户持有的数字资产分叉，且不论该等数字资产分叉后是否会成为主导数字资产。如果我们选择支持数字资产的分叉，我们将通过UpWallet官网或我们认为合适的其他方式发布公告。
             <br/>
                您知晓我们无法控制也没有能力影响分叉的创建或实现。UpWallet无法就任何数字资产的安全性、功能或供应提供任何保证，包括新的主导数字资产或受相关分叉支配的其他数字资产。您可能无法通过平台交易分叉的数字资产，并且可能会损失与相关数字资产有关的任何价值。
             <br/>
                15.3.	担保数字资产。UpWallet可能会不时支持声称由其他资产担保或以其他方式与其他资产捆绑或挂钩的数字资产，包括数字资产、法币或金银等大宗商品（“担保数字资产”）。您同意您是在阅读、理解并接受与各特定担保数字资产相关的所有条款与条件以及风险之后才开展与其相关的任何交易。UpWallet没有义务购买、回购或赎回或促进赎回您的担保数字资产。UpWallet保留更改、暂停或中止与任何担保数字资产相关的任何服务的权利。关于任何特定担保数字资产是否会保持其对任何资产的价值，以及各发行人或任何第三方持有的任何担保数字资产储备金或抵押品的数量或质量，UpWallet不作任何陈述。
             <br/>
             <br/>
               <h3> 16.	账户安全</h3>

                16.1.	您的责任。您自行负责采取适当措施，保护您的硬件和数据免受病毒和恶意软件以及任何不当材料侵害。除非适用法律另有规定，否则您自行负责备份并维护您通过UpWallet服务存储或传输的任何信息的副本。UpWallet对您未能遵守本条款规定而遭受的任何索赔或损失概不负责。
             <br/>
                16.2.	安全措施。您和任何许可用户应始终确保您所有访问ID均充分安全在控。您负责采取必要的安全措施（或确保您的许可用户采取该等措施）保护您的UpWallet账户并确保您的访问ID安全，包括：
             <br/>
                a.	严格遵守UpWallet所有机制或流程；
             <br/>
                b.	创建高强度密码，确保您的访问ID安全在控；
             <br/>
                c.	保证提供给UpWallet的电子邮箱和电话号码是最新的，以便接收UpWallet可能向您发送的任何通知或警告；
             <br/>
                d.	登录UpWallet账户时，请勿允许他人远程访问或与他人共享您的计算机和/或电脑屏幕；
             <br/>
                e.	请牢记：UpWallet在任何情况下均不会要求您分享您的密码或双重身份验证代码；并且
             <br/>
                f.	每次访问结束时，请从网站或平台退出。
             <br/>
                您必须确保电子邮件账户和访问ID安全，使它们免遭任何攻击和未经授权的访问。如果您获悉或有理由怀疑，您的电子邮箱或某许可用户的电子邮箱安全受到威胁，或者您或任何许可用户的电子邮箱遭非法使用，必须立即通知我们。
             <br/>
                16.3.	监控账户历史记录。您应该监控历史记录，以便发现您UpWallet账户中任何未经授权或可疑的活动并尽快通知我们。您知晓任何安全漏洞均可能导致第三方未经授权访问您的UpWallet账户，并导致您UpWallet账户以及任何关联账户（包括您的关联银行账户和信用卡）的任何数字资产和/或资金丢失或被盗。
             <br/>
                16.4.	如果怀疑存在安全漏洞，您必须确保：
             <br/>
                a.	通过第3.1条规定的联系方式立即通知我们，并在整个安全漏洞持续期间，继续向我们提供准确的最新信息；
             <br/>
                b.	立即通过平台的禁用账户功能或UpWallet不时规定的任何其他方法，锁定您的UpWallet账户；并
             <br/>
                c.	采取UpWallet可能合理要求的任何其他步骤，减少、管理或报告任何安全漏洞。
             <br/>
                UpWallet保留要求您提供UpWallet认为与实际或可疑安全漏洞相关或必要的任何及所有信息和文件的权利，且您同意提供该等信息和文件，并且UpWallet有权向任何第三方提供该等信息以便解决任何安全漏洞。
             <br/>
                其他重要信息
             <br/>
             <br/>
               <h3> 17.	隐私</h3>

                17.1.	《隐私声明》。与本条款、UpWallet服务、平台和任何网站相关的个人数据收集和使用，详见《隐私声明》（不时更新）。您知晓UpWallet会根据《隐私声明》处理与您相关的个人数据、您提供给UpWallet的个人数据或UpWallet因本条款向您收集的个人数据。您的个人数据将根据《隐私声明》进行处理，《隐私声明》构成本条款的一部分。
             <br/>
                您陈述并保证：
             <br/>
                a. 您承认您已阅读并理解《隐私声明》。
             <br/>
                b. UpWallet业务经常发生变更，《隐私声明》也会随之变更。因此，在UpWallet不时向您提供《隐私声明》的替代版本后，您应立即阅读其替代版。
             <br/>
             <br/>
               <h3> 18.	条款变更等</h3>
                18.1.	UpWallet如何以及何时做出变更。UpWallet可随时对本条款以及通过引用纳入的任何条款与条件（包括任何《产品条款》）进行变更，您接受本条款即表示您明确同意该等变更。UpWallet将通过电子邮件或UpWallet官网告知进行的任何变更。
             <br/>
                大多数变更我们会提前通知，并在变更生效前通知您。但我们偶尔可能须在未提前通知您的情况下进行变更。这种情况可能包括：
             <br/>
                a.	根据法律和/或法规变化做出的变更；
             <br/>
                b.	变更符合您的利益；
             <br/>
                c.	变更旨在让您更清楚地了解本条款；以及/或者
             <br/>
                d.	出于其他合理理由导致来不及通知您。
             <br/>
                对于我们无法提前通知您的情形，我们会在变更完成后尽快通知您。
             <br/>
                18.2.	变更何时生效。除变更立即生效的情况外，更新后条款将在我们向您发出通知后生效。如果您不接受该等变更，则您可根据本条款第19.1条的规定关闭UpWallet账户。如果我们未收到您的意见，则我们认为您已接受变更，且您继续访问或使用UpWallet服务即视为您接受更新后的条款。
             <br/>
                18.3.	手续费变更。UpWallet也可对官网《手续费阶梯结构》页面载明的费用进行变更，包括添加新手续费和/或收费。如果您不接受该等变更，可根据本条款第19.1条的规定关闭UpWallet账户。如果我们未收到您的意见，将认为您已接受变更，且您继续访问或使用UpWallet服务即视为您接受更新后的本条款。
             <br/>
             <br/>
               <h3> 19.	关闭您的UpWallet账户</h3>

                19.1.	您有关闭UpWallet账户的权利。您可以按照UpWallet不时规定的账户终止程序随时终止UpWallet账户。关闭UpWallet账户不收取费用，但须支付对UpWallet的所有欠款。您授权UpWallet在您关闭账户时取消或暂停任何待处理交易，并从您UpWallet账户中扣除欠付UpWallet的任何款项。
             <br/>
                某些情况下您可能无法关闭UpWallet账户，这些情况包括：
             <br/>
                a.	试图逃避有关当局的调查；
             <br/>
                b.	有待处理交易或未决索赔；
             <br/>
                c.	UpWallet账户有欠付UpWallet的款项；或者
             <br/>
                d.	UpWallet账户被冻结、扣留、限制或保留。
             <br/>
                19.2.	您关闭UpWallet账户后会怎样。如果您关闭UpWallet账户，须将UpWallet账户中的所有数字资产提现。如果您未将数字资产提现或者连续90日未访问UpWallet账户，UpWallet将给您发通知，告知将把您的账户视为休眠账户。
             <br/>
                19.3.	您的账户休眠后会怎样。如果您未在30日内对根据第19.2条发出的通知做出回应，则UpWallet可：
             <br/>
                a.	将您的UpWallet账户标记为休眠账户；
             <br/>
                b.	将该数字资产兑换成其他数字资产。UpWallet对您因该等变更而遭受的任何利润损失、承担的纳税义务或遭受任何其他损失、损害或费用，不承担任何责任；
             <br/>
                c.	在UpWallet认为合理必要的情况下，将该等休眠账户和/或账户中持有的任何数字资产转让给任何第三方（包括但不限于其他UpWallet联盟伙伴、任何第三方托管人或独立钱包）。进行该等转让后，您有权收回数字资产，但前提是须满足UpWallet（或相关第三方）的合理认证要求及任何其他使用条款与条件；
             <br/>
                d.	调整您的休眠账户，这样一来，您将收到关于数字资产数量和类型的合同索赔，这些资产在兑换至休眠账户之前存放在您的UpWallet账户中；
             <br/>
                e.	收取休眠账户费用，该等费用可用于支付任何UpWallet联盟伙伴或任何第三方维护数字资产的成本，每月直接从休眠账户中划走；以及
             <br/>
                f.	随时关闭休眠账户。
             <br/>
                休眠账户关闭后不能再重新激活，如果您希望继续使用UpWallet服务，须根据本条款注册新的UpWallet账户。您承认UpWallet没有义务向您的休眠账户支付任何根据适用《产品条款》原本同意支付的与该账户贷记资产相关的任何报酬、奖励或利息。
             <br/>
             <br/>
                <h3>20.	终止、暂停、搁置和限制</h3>

                20.1.	UpWallet的权利。UpWallet可随时临时或永久性地修改或停止UpWallet服务的任何部分或功能。特别是，UpWallet可以：(1) 拒绝完成或阻止、取消或在适用法律允许的情况下拒绝撤销您授权的任何交易；(2) 终止、暂停或限制您访问UpWallet任何或所有服务；(3) 终止、暂停、关闭、搁置或限制您访问任何或所有UpWallet账户；(4) 拒绝向第三方（包括但不限于第三方钱包运营商）传输信息或指令；以及/或者(5) 采取UpWallet认为必要的任何行动。每种情况下均立即生效且可出于任何原因，包括但不限于：
             <br/>
                a.	您没有资格或不再具备使用一项或多项UpWallet服务的资格；
             <br/>
                b.	UpWallet有理由怀疑：
             <br/>
                i.	登录您UpWallet账户并非您本人，或者UpWallet怀疑您的UpWallet账户已经或将用于任何非法、欺诈或未经授权的目的；
             <br/>
                ii.	登录您UpWallet账户并非受许可用户，或者UpWallet怀疑您的UpWallet企业账户已经或将用于任何非法、欺诈或未经授权的目的；
             <br/>
                iii.	多个自然人访问和/或使用同一UpWallet账户进行交易，或者UpWallet怀疑UpWallet账户已经或将用于任何非法、欺诈或未经授权的目的；
             <br/>
                iv.	您提供的信息错误、不真实、过时或不完整；
             <br/>
                c.	UpWallet对您的信誉或财务状况有合理担忧，包括：
             <br/>
                i.	如果您是个人用户，而您已破产、精神失常、申请破产、或已启动起诉您破产的诉讼；
             <br/>
                ii.	如果您是合伙企业，而您的任何合伙人死亡或破产或精神失常、申请破产、已启动起诉任何合伙人破产的诉讼、或已提起解散和/或变更合伙人或合伙企业章程的诉讼；
             <br/>
                iii.	如果您是公司，而公司无力偿还到期债务，或已启动起诉公司资不抵债、接受司法管理、破产在管、行政管理或任何类似或类似程序的诉讼；
             <br/>
                iv.	您召集债权人会议，或为了债权人利益提议或实际与债权人达成任何妥协或安排或进行任何转让；
             <br/>
                d.	尚未根据第8条要求提交信息和文件；
             <br/>
                e.	尚未根据第7.4条要求提交强化尽职调查；
             <br/>
                f.	UpWallet有理由认为，适用法律或UpWallet所在任何司法管辖区的任何法院或当局要求我们这样做；
             <br/>
                g.	UpWallet已经确定或怀疑：
             <br/>
                i.	您违反了本条款或任何《产品条款》；
             <br/>
                ii.	您违反了本条款中任何明示或暗示的保证或您做出的任何声明；
             <br/>
                iii.	任何交易未经授权、错误、欺诈或非法，或者UpWallet已经确定或怀疑，您正在以欺诈、未经授权或非法方式使用UpWallet账户或UpWallet服务；
             <br/>
                iv.	您的UpWallet账户或您对UpWallet服务的使用，存在洗钱、恐怖融资、欺诈或任何其他犯罪行为；
             <br/>
                h.	您UpWallet账户的使用受任何未决、正在进行或可能发生的诉讼、调查或司法、政府或监管流程制约，和/或UpWallet认为您的UpWallet账户活动存在较高的法律或监管违规风险；
             <br/>
                i.	您欠UpWallet的款项尚未付清，而无论是因退款还是任何其他原因导致的欠款；
             <br/>
                j.	发送到您电子邮箱的任何电子邮件因无法送达被退回；
             <br/>
                k.	验证您的身份时出现问题；
             <br/>
                l.	您采取了规避我们控制的任何行动，如未经UpWallet书面同意即开立多个UpWallet账户，或滥用UpWallet不时提供的推广活动；或者
             <br/>
                m.	我们有其他合理理由需要这样做。
             <br/>
                UpWallet将采取合理措施向您提供适当通知。但有时根据适用法律要求，我们不能提供适当通知。
             <br/>
                20.2.	您做出的承认表示。您承认并同意：
             <br/>
                a.	上述第20.1条所述UpWallet为终止、暂停、关闭或限制您访问UpWallet账户和/或UpWallet服务而采取的行动示例并非全部；且
             <br/>
                b.	UpWallet采取某些行动的决定，包括但不限于终止、暂停或限制您访问UpWallet账户或UpWallet服务，可能以对UpWallet风险管理和安全协议至关重要的保密标准为依据。您同意：UpWallet没有义务向您披露UpWallet风险管理和安全程序的详情。
             <br/>
                20.3.	UpWallet行使权利后会怎样。如果UpWallet终止、暂停、搁置或限制您访问一项或多项UpWallet服务：
             <br/>
                a.	如果您有指令、交易、头寸或未平仓交易，则它们可能被您或我们平仓，具体取决于我们采取终止、暂停、搁置、限制还是其他行动；
             <br/>
                b.	UpWallet账户或UpWallet服务任何使用退款，可能导致立即暂停和/或限制您的UpWallet账户和UpWallet服务；
             <br/>
                c.	要重新激活暂停和/或受限的UpWallet账户或UpWallet服务，您需要向我们退还全部退款，包括欠我们的任何金额（包括任何适用的费用）；并且
             <br/>
                d.	如需退款，则您承担任何入账金额且您授权我们直接从您UpWallet账户的任何资产中扣除成本和费用，无需另行通知。
             <br/>
                20.4.	非法拥有。如果UpWallet被告知并有理由相信，您UpWallet账户持有的任何数字资产或法定货币被盗或并非您合法拥有（无论是因为错误还是其他原因），则UpWallet均可以（但没有义务）冻结受影响资金和您的UpWallet账户。如果UpWallet扣留您UpWallet账户中的部分或全部数字资产或法币或者您整个UpWallet账户，直至我们可以接受的证据证明您有权拥有您UpWallet账户中的数字资产和/或法币为止。UpWallet不会介入与您UpWallet账户中的任何数字资产和/或法币相关的争议或争议的解决。
             <br/>
                20.5.	在其他司法管辖区访问UpWallet服务。某些国家的居民可能只能访问部分而非全部UpWallet服务。UpWallet可能会不时更改为您提供的UpWallet服务。如果您去往UpWallet《受禁国家/地区清单》中列明的国家/地区，将无法使用UpWallet服务或者会受UpWallet服务屏蔽。您知晓这会影响您在平台交易、和/或监控任何现有订单或未平仓头寸、或以其它方式使用UpWallet服务的能力。您不得试图以任何方式规避任何该等限制，包括通过使用任何虚拟专用网络修改您的互联网协议地址。
             <br/>
                知识产权
             <br/>

                21.	技术免责声明

                21.1.	不做陈述或保证。网站和平台提供的UpWallet服务和任何信息，包括聊天服务均以“原样”和“可用”方式提供，在适用法律允许的最大范围内，未提供任何明示或暗示的陈述或保证。具体而言，UpWallet否认对所有权、适销性、特定用途适用性和/或不侵权的任何暗示担保。UpWallet未陈述或保证访问网站、平台、您的任何UpWallet账户、UpWallet服务或它们当中包含的任何材料是连续的、不间断的、及时的或无差错的。这可能会导致一段时间无法在平台开展交易并且还可能会导致时延。

                21.2.	暂停访问。UpWallet可能会不时暂停访问UpWallet账户和/或UpWallet服务，以便进行定期维护和紧急维护。UpWallet将尽合理努力确保及时处理平台上的交易，但对完成处理所需的时间未做任何陈述或保证，该时间取决于许多超出我们控制范围的因素。

                21.3.	内容。尽管我们会尽合理努力更新网站和平台上的信息，但未对其内容包括与UpWallet服务相关的信息做出其是准确的、完整的或最新的任何明示或暗示陈述、保证或担保。

                21.4.	第三方网站。提供与第三方网站的链接（包括但不限于第三方网站中的内容、材料和/或信息）旨为提供方便，但它们不受我们控制。您承认并同意：UpWallet对可从平台或网站访问或链接的任何第三方网站包含的内容、材料、信息或服务的任何方面概不负责。

                21.5.	网络访问和兼容性：您自行负责获得使用UpWallet服务所需的数据网络访问权限。您自行负责获取并更新，访问和使用UpWallet服务、网站及其任何更新所需的兼容硬件或设备。UpWallet不保证UpWallet服务或其任何部分，可在任何特定硬件或设备上运行。UpWallet服务可能会受到互联网和电子通信固有的故障和延迟影响。
            </div>
        </Content>
    )
}

const Content = styled.main`
  display: block;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
