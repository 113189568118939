import React from "react";
import styled from "styled-components"

export default function Privacy() {
    return (
        <Content className="container updated-privacy-policy">
            <div className='content-wrapper' style={{paddingTop:'0.5rem'}}>

               <h1> Privacy Policy</h1>
                <h3>最后更新时间：2024 年 5 月 28 日</h3>
                UpWallet致力于保护客户的隐私，并高度重视我们的数据保护责任。
             <br/>
                本隐私声明描述了UpWallet如何通过本隐私声明中提及的UpWallet网站和应用程序收集和处理您的个人数据。UpWallet是指由UpWallet网站（其域名包括但不限于 www.upclouds.xyz/en）、移动应用程序、客户端、小程序和其他为提供UpWallet服务而开发的应用程序组成的生态系统，包括生态系统内独立运营的平台、网站和客户端（例如UpWallet的开放平台、UpWallet Launchpad、UpWallet实验室、UpWallet慈善机构、UpWallet DEX、UpWallet X、JEX 和法币网关）。
             <br/>
                本隐私声明适用于我们在UpWallet平台、网站和部门开展的所有个人数据处理活动。
             <br/>
                如果您是我们服务的客户或用户，本隐私声明与任何业务条款和其他合同文件一起适用，包括但不限于我们与您达成的任何协议。
             <br/>
                如果您不是我们服务的相关利益相关者、客户或用户，但正在使用我们的网站，则本隐私声明也与我们的 Cookie 声明一起适用于您。
             <br/>
                因此，本通知应与我们的 Cookie 通知一起阅读，后者提供了有关我们在网站上使用 Cookie 的更多详细信息。我们的 Cookie 通知可在此处访问。
             <br/>
             <br/>
               <h3> 1. UpWallet收集和处理哪些个人信息？</h3>

                个人信息是可识别个人或与可识别个人相关的数据。这包括您向我们提供的信息、自动收集的有关您的信息以及我们从第三方获得的信息。
             <br/>
                您向我们提供的信息。要开设帐户并访问我们的服务，我们会要求您向我们提供一些关于您自己的信息。这些信息要么是法律要求的（例如，为了验证您的身份并遵守“了解您的客户”义务），要么是提供所请求服务所必需的（例如，您需要提供您的电子邮件地址才能开设您的帐户），要么是与某些特定目的相关的，下文将更详细地描述。在某些情况下，如果我们添加服务和功能，您可能会被要求向我们提供更多信息。
             <br/>
                如果未能提供所需数据，则UpWallet将无法向您提供我们的服务。
             <br/>
                我们可能会收集您的以下类型的信息：
             <br/>
                设备 ID、浏览信息（如名称和版本）、互联网协议（“IP”）地址、互联网连接数据、运营商和运营商数据、登录数据、浏览器类型和版本、设备类型类别和型号、时区设置和位置数据、语言数据、应用程序版本、浏览器插件类型和版本、操作系统和平台、您访问我们的平台时允许我们访问的设备上存储的或可用的其他信息
             <br/>
                身份验证数据、安全问题、点击流数据、公共社交网络帖子以及通过 cookie 或类似技术收集的其他数据。
             <br/>
                有关您使用我们的服务时其运行情况的信息，例如，您收到的错误消息、网站性能信息、其他诊断数据。
             <br/>
             <br/>
                <h3>2. UpWallet为什么要处理我的个人数据？我们依据哪些法律依据来收集和处理您的个人数据？</h3>
                我们收集个人信息的主要目的是以安全、高效、顺畅的方式提供我们的服务。我们通常使用您的个人信息来交付、提供、运营我们的服务，以及用于内容和广告，以及用于防损和反欺诈目的。下面您将找到关于我们如何使用自动化个人决策（包括分析）的解释。UpWallet并不完全依赖自动化工具来帮助确定交易或客户账户是否存在欺诈或法律风险。
             <br/>
                UpWallet为什么要处理我的个人数据？
             <br/>
                我们使用个人数据的法律依据（欧洲经济区和瑞士欧盟 GDPR）
             <br/>
                - 管理我们与您的合同关系。创建和维护您的帐户。
             <br/>
                这包括我们使用您的个人数据来接收和处理订单以及处理付款的情况。
             <br/>
                处理的个人数据类别包括个人身份信息、机构信息、联系信息、财务信息。不出于上述目的处理您的个人数据的后果是无法在我们这里开设帐户或终止您已开设的帐户。
             <br/>
                为了履行您作为一方当事人的合同，处理是必要的。
             <br/>
                - 保持法律和法规合规性
             <br/>
                我们的大部分核心服务（例如兑换服务）都受到严格和具体的法律法规的约束，这些法律法规要求我们收集、使用和存储某些个人数据并处理个人身份信息，在某些情况下还处理敏感个人数据（包括生物识别信息）（详见第一部分），例如遵守适用法律法规规定的了解您的客户（“KYC”）义务，特别是遵守反洗钱法律法规。
             <br/>
                个人信息的类别包括个人身份信息、机构信息、敏感和生物特征信息、政府标识符、联系信息、财务信息。如果您不提供法律要求的个人信息，您可能无法开设账户，或者我们可能不得不关闭您已开设的账户。
             <br/>
                为了履行我们根据适用法律法规以及反洗钱法律法规所承担的法律义务，处理是必要的。

             <br/>
                根据欧盟或欧盟成员国法律，出于重大公共利益的原因，处理是必要的。我们遵守欧盟反洗钱指令和相关欧盟成员国实施该指令的法律，这些法律要求我们处理您的身份证件中的信息，包括您的照片和您的脸部图像（所谓的“活体检查”）。
             <br/>
                -确保您的个人资料的准确性
             <br/>
                当您向我们提供身份证件时，我们会处理您的个人数据。我们使用人工智能 OCR 技术提取重要数据，以确保信息的准确性并提高业务效率。OCR 引擎克服了传统 OCR 的局限性，能够高度准确地提取数据。
             <br/>
                为了我们追求改善服务和增强用户体验的合法利益，处理是必要的。
             <br/>
                - 就服务和交易相关事宜与您沟通。
             <br/>
                我们使用您的个人数据与您就UpWallet服务的管理或账户相关信息进行沟通。我们将与您沟通，让您了解我们的服务的最新情况，例如，告知您相关的安全问题、更新或提供其他交易相关信息。如果没有此类沟通，您可能无法了解与您的账户相关的重要动态，而这些动态可能会影响您使用我们服务的方式。您无法选择不接收关键服务通信，例如出于法律或安全目的发送的电子邮件或移动通知。
             <br/>
                为了履行您作为一方当事人的合同，处理是必要的。
             <br/>
                - 提供客户服务：
             <br/>
                当您联系我们时，我们会处理您的个人数据，以便针对问题、争议、投诉、解决问题等提供支持。
             <br/>
                处理的个人数据类别包括个人身份数据、机构信息、交易信息、通讯、联系信息、财务信息、浏览信息、使用数据。
             <br/>
                如果不为此目的处理您的个人数据，我们将无法响应您的请求。
             <br/>
                为了履行您作为一方当事人的合同，处理是必要的。
             <br/>
                为了我们追求改善服务和增强用户体验的合法利益，处理是必要的。
             <br/>
                - 促进我们平台的安全性、可靠性和完整性。
             <br/>
                我们处理您的个人数据是为了增强安全性、监控和验证身份或服务访问、打击恶意软件或安全风险并遵守适用的安全法律法规。
             <br/>
                我们处理您的个人数据以验证帐户和相关活动，查找和处理违反我们的条款和条件的行为，调查可疑活动，检测、预防和打击非法行为，检测欺诈行为，并维护我们服务的完整性。
             <br/>
                处理的个人数据类别包括个人身份信息、机构信息、交易信息、联系信息、财务信息、浏览信息、使用数据。
             <br/>
                如果不处理您的个人信息，我们可能无法确保我们服务的安全。
             <br/>
                我们使用您的个人数据来提供功能、分析性能、修复错误并提高UpWallet服务的可用性和有效性。
             <br/>
                为了履行您作为一方当事人的合同，处理是必要的。
             <br/>
                - 促进我们服务的安全性、可靠性和完整性。
             <br/>
                欺诈预防和检测以及信用风险。
             <br/>
                处理的个人数据类别包括个人身份数据、机构信息、交易信息、联系信息、财务信息、浏览信息、使用数据。
             <br/>
                我们处理个人身份信息是为了防止和检测、防止和减轻对我们服务的欺诈和滥用，保护您免受账户泄露或资金损失，确保我们的用户、UpWallet服务和其他服务的安全。我们还可能使用评分方法来评估和管理信用风险。请注意，我们可能会出于风险和欺诈检测的目的而进行自动决策。当我们这样做时，我们会采取适当措施来保障您的权利和自由以及合法利益，包括获得人为干预、表达您的观点和对决定提出异议的权利。请参阅下面的第 10 节了解更多信息。
             <br/>
                为了我们追求的合法利益和我们用户的利益，处理是必要的，例如，当我们发现并防止欺诈和滥用以保护我们用户、我们自己或他人的安全时；
             <br/>
                - 提供UpWallet服务。
             <br/>
                我们处理您的个人数据是为了向您提供服务、处理您的订单、促进交易并完成用户要求的交易。例如，当您想在我们的平台上使用兑换服务时，我们会要求您提供某些信息，例如您的身份证明、联系信息和付款信息。
             <br/>
                处理的个人数据类别包括个人身份数据、机构信息、交易信息、联系信息、财务信息、浏览信息、使用数据。
             <br/>
                如果没有这些信息，我们就无法为您提供服务。
             <br/>
                为了履行您作为一方当事人的合同，处理是必要的。
             <br/>
                为了我们的合法利益以及您为交易提供更好的文件的利益，处理是必要的。
             <br/>
                -将社交媒体平台或广告平台的服务用于营销等目的
             <br/>
                -处理的个人数据类别是使用数据、浏览信息。
             <br/>
                我们依赖您的同意来处理您的个人信息以使用广告平台的服务。当您同意为特定目的处理您的个人信息时，您可以随时撤回您的同意，我们将停止为该目的处理您的个人信息。撤回同意并不影响撤回前基于同意进行的处理的合法性。
             <br/>
                - 改善我们的服务。
             <br/>
                我们处理个人数据是为了改善我们的服务并为您提供更好的用户体验；
             <br/>
                处理的个人数据类别是个人身份数据、机构信息、交易信息、浏览信息、使用数据。
             <br/>
                为了我们追求改善服务和增强用户体验的合法利益，处理是必要的。
             <br/>
                - 为您提供促销信息
             <br/>
                我们使用您的信息为您提供促销活动，包括优惠、奖励和其他使用我们服务的激励措施。这还包括让您参加抽奖、比赛或完成调查。
             <br/>
                处理的个人数据类别包括个人身份数据、机构信息、交易信息、浏览信息、使用数据和在线标识符。
             <br/>
                对于非用户，为了我们的合法利益和您的利益，处理是必要的，以奖励您的客户忠诚度。
             <br/>


                对于用户，我们依靠您的同意来处理您的个人信息，以便为您提供促销信息。当您同意为特定目的处理您的个人信息时，您可以随时撤回您的同意，我们将停止为该目的处理您的个人信息。撤回同意不会影响撤回前基于同意进行的处理的合法性。
             <br/>
                ——开展研究和创新。
             <br/>
                我们会进行调查，以进一步了解您使用我们服务的体验。我们还使用您的信息来支持研发并推动我们服务和产品的创新。这些信息还将用于营销目的。
             <br/>
                为了实现我们的合法利益，即通过从这些调查中获得的信息来改进和运行我们的服务，处理是必要的。
             <br/>
                -用于内部业务目的和记录保存。

             <br/>

                处理的个人数据类别是个人身份数据、财务信息、交易信息和浏览信息。
             <br/>
                为了我们追求的合法利益，处理是必要的，以保存记录以确保您遵守管理我们与您之间关系的协议（“条款和条件”）所规定的合同义务。

             <br/>

                为了履行我们的法律义务，处理是必要的，即为内部业务和研究目的以及记录保存目的保存某些记录。
             <br/>
                - 推荐和个性化。
             <br/>
                我们使用您的个人信息来推荐您可能感兴趣的功能和服务，识别您的偏好，并个性化您使用UpWallet服务的体验；
             <br/>
                为了维护我们向客户提供个性化服务的合法利益，处理是必要的。
             <br/>
                为了履行您作为一方当事人的合同，处理是必要的。
             <br/>
                - 向您提供营销信息。
             <br/>
                我们根据您的同意使用您的信息通过电子邮件、移动设备、应用程序内和推送通知向您发送有针对性的营销信息。
             <br/>
                我们还会使用您的信息进行营销目的分析。
             <br/>
                处理的个人数据类别包括个人身份信息、机构信息、交易信息、浏览信息、使用数据、营销和研究信息、通讯。
             <br/>
                在适用法律要求的情况下，我们会根据您的同意将您的个人信息用于营销目的。当您同意为特定目的处理您的个人信息时，您可以随时撤回您的同意，我们将停止为该目的处理您的个人信息。撤回同意不会影响撤回前基于同意进行的处理的合法性。
             <br/>
                当我们为了营销目的进行分析时，例如确定您可能对哪些服务或促销活动感兴趣，这种处理是基于合法利益的。
             <br/>
                即使您选择退出营销通讯，与我们的业务关系有关的交易账户信息和通信也不会受到影响。
             <br/>
                - 活动（i） 主办和/或组织活动。
             <br/>
                UpWallet全年举办许多现场活动。例如，区块链周等活动。如果您注册参加我们的某项活动并且您是用户，我们将访问您帐户中的信息，以便为您提供与活动相关的信息和服务。注册活动时，可能会要求您提供更多信息。
             <br/>
                如果您不是用户并报名参加我们的某项活动，我们将收集以下信息：姓名、电子邮件、公司、职称、行业、地址、电话号码、是否用餐偏好、社交媒体域名等。

             <br/>

                (ii) 在某些情况下，您可能会付费参加活动。在这种情况下，我们将访问您帐户中的信息，以便为您提供与活动相关的信息和服务并促进购票流程。在报名参加活动时，您可能会被要求提供更多信息。
             <br/>


                (iii) 如果您是我们某项活动的主持人，我们将收集您的信息，包括您的姓名、雇主和联系信息以及照片，我们还可能收集活动参与者对您作为主持人的表现进行评估后提供的信息。在某些情况下，我们还可能录制并存储您的声音和肖像。

             <br/>

                (iv) 我们的部分活动是赞助活动。UpWallet可能会向我们活动的赞助商、联合赞助商和参展商提供一份参会者名单。UpWallet还可能允许赞助商、联合赞助商、参展商或其他与活动组织相关的第三方在每场赞助活动中通过邮件向您发送一次材料，在这种情况下，UpWallet会聘请第三方邮寄公司，不会直接与赞助商/参展商共享您的邮寄地址。如果您不希望您的信息被列入参会者名单或不希望收到赞助商、联合赞助商和/或参展商的信息，您可以在注册活动时表达您的偏好，也可以直接通过 dpo@upclouds.xyz 联系UpWallet。我们确实为参会者提供了不接收赞助商或UpWallet营销信息的选择。

                <br/>

                (iv) 为您提供有关您已注册的活动的信息，例如活动更新和可能的变更、取消或类似信息。
                <br/>


                （五）履行和监督我们的法律责任，例如公共安全法规规定的责任

                <br/>

                (vi) 根据您的偏好，与您沟通我们提供的其他活动、新闻和服务
                <br/>


                （vii）规划更好的未来活动并提升与会者的体验。
                <br/>
                为了我们制作、组织和举办活动的合法利益，处理是必要的。
                <br/>
                如果您付费参加活动，则为了履行您作为一方当事人的合同，有必要进行处理。
                <br/>
                为了实现我们宣传活动、告知活动或评估活动表现的合法利益，处理是必要的。
                <br/>
                我们依赖您的同意来处理您的个人信息以用于营销目的。当您同意为特定目的处理您的个人信息时，您可以随时撤回您的同意，我们将停止为该目的处理您的个人信息。撤回同意不会影响撤回前基于同意进行的处理的合法性。


                <br/>
                进一步的细节如下：

                <br/>

                当您决定参加某项活动或担任活动主持人时，我们会根据您的同意来处理您参加活动的个人数据。当您同意为特定目的处理您的个人数据时，您可以随时撤回您的同意，我们将停止为此目的处理您的个人数据。如果您是我们某项活动的主持人，在某些情况下，我们可能还会拍照、制作和存储您的声音和肖像的录音。在这些情况下，UpWallet依赖合法利益基础来收集、存储和处理这些个人数据。当我们组织活动并与赞助商、联合赞助商和参展商合作并发送材料时，我们会根据您的同意向您发送有关活动的材料或直接营销电子邮件。当您同意为特定目的处理您的个人数据时，您可以随时撤回您的同意，我们将停止为此目的处理您的个人数据。
                <br/>
                Cookies：我们在服务中使用 cookies 和类似技术。处理的个人数据类别是来自 Cookies 的信息。
                <br/>
                在适用法律要求的情况下，我们会根据您的同意放置 Cookie 和类似技术。当您同意为特定目的处理您的个人信息时，您可以随时撤回您的同意，我们将停止为该目的处理您的个人信息。
                <br/>
                撤回同意不会影响撤回前基于同意进行的处理的合法性
                <br/>
                遵守其他法律和监管义务。
                <br/>
                当我们认为为了遵守法律、法律义务、法规、执法、政府和其他法律要求、法院命令或根据第 6 条规定的详细信息向税务机关披露是合理必要时，我们可能会访问、阅读、保存和披露信息。
                <br/>
                处理的个人数据类别包括个人身份信息、机构信息、财务信息、交易信息、浏览信息、使用数据、区块链数据。
                <br/>
                为了遵守适用法律法规规定的法律义务，处理是必要的
                <br/>
                当我们不受适用法律强制但真诚地相信相关司法管辖区的法律有此要求时，我们还可能依赖合法利益来响应法律要求。
                <br/>
                <br/>
               <h3> 3.儿童可以使用UpWallet服务吗？</h3>

                UpWallet不允许未满 18 周岁的任何人使用UpWallet服务，我们不会故意索取或收集任何未满 18 周岁的人士的信息。如果您未满 18 周岁，请不要向UpWallet服务提供任何个人信息。
                <br/>
                如果提交个人信息的用户或客户被怀疑未满18岁，UpWallet将要求相关客户或用户关闭其账户，并将采取措施尽快删除该个人的个人信息。
                <br/>
               <br/>
               <h3> 4. 什么是Cookies和其他标识符？</h3>

                我们使用 Cookie 和类似工具来增强您的用户体验、提供我们的服务、加强我们的营销工作并了解客户如何使用我们的服务，以便我们进行改进。根据您所在地区的适用法律，浏览器上的 Cookie 横幅将告诉您如何接受或拒绝 Cookie。我们的 Cookie 政策副本可在此处获取。
                <br/>
                <br/>
                <h3> 5. 我们如何以及为何共享您的个人数据？</h3>
                如果我们认为共享您的个人信息符合与您（包括UpWallet条款和条件）或我们的任何合同关系、适用法律、法规或法律程序的要求，我们可能会与第三方（包括其他UpWallet实体）共享您的个人信息。在与其他UpWallet实体共享您的个人信息时，我们将尽最大努力确保这些实体遵守本隐私声明，或遵循至少与本隐私声明中所述同样具有保护性的做法。例如，取决于您居住的地方和负责运行 KYC 检查的实体。有关更多信息，请参阅第 1 节。
                <br/>
                我们还可能与以下人员或在以下情况下共享个人信息：
                <br/>
                关联方：我们处理和收集的个人数据可能会在UpWallet公司之间转移，这是开展业务和向您提供服务的正常过程。请参阅第 1 节“UpWallet与您的关系”和第 2 节“UpWallet收集和处理哪些个人数据？”。
                <br/>
                第三方：我们雇用其他公司和个人代表我们执行职能。示例包括分析数据、提供营销协助、处理付款、传输内容以及评估和管理信用风险。第三方服务提供商只能访问执行其职能所需的个人信息，但不得将其用于其他目的。此外，他们必须根据我们的合同协议处理个人信息，并且只能在适用的数据保护法允许的范围内处理。根据适用法律的规定，在行使您的数据可移植性权利时，您的个人数据也可能由第三方（即另一个数据控制者）共享。有关数据可移植性的更多信息，请参阅第 10 节。当您使用第三方服务（例如当您将您的UpWallet账户与您的银行账户连接时）或通过我们的服务链接的网站时，这些服务或产品的提供商可能会收到UpWallet、您或其他人与他们共享的有关您的信息。请注意，当您使用不受本隐私政策管辖的第三方服务或UpWallet附属服务时，他们自己的条款和隐私政策将管辖您对这些服务和产品的使用。
                <br/>
                法律机构：我们可能会与法院、执法机构、监管机构、律师或其他第三方共享您的信息：（a）遵守法律和法律义务；（b）为建立、行使或辩护法律或衡平法上的索赔；（c）响应执法和监管要求，包括（1）当我们被传票、法院命令、搜查或扣押令或类似的法律程序强制要求这样做时，或（2）根据司法协助条约（MLAT）或请求书的国际执法请求；（d）遵守一种或多种形式的“旅行规则”，这些规则要求我们将您的信息传输给另一家金融机构、监管机构或其他行业合作伙伴；（e）当我们真诚地相信披露个人信息对于保护我们的客户、UpWallet或其他人的权利、财产或安全是必要的，包括防止迫在眉睫的人身伤害或重大财务损失；（f）调查违反我们的使用条款或其他适用政策的行为； (g) 检测、调查、预防或处理欺诈或信用风险、其他非法活动或安全和技术问题，报告涉嫌非法活动或协助执法部门调查涉嫌非法或不法活动。
                <br/>
                业务转让：随着我们业务的不断发展，我们可能会出售或购买其他业务或服务。在此类交易中，用户信息通常是转让的业务资产之一，但仍受任何现有隐私声明中的承诺约束（当然，除非用户另行同意）。此外，在极少数情况下，UpWallet或其大部分资产被第三方收购，用户信息将成为转让的资产之一。
                <br/>
                征得您的同意：我们还可能按照在收集信息时或共享信息之前向您发出的通知中所述的方式，或以您同意的任何其他方式披露您的个人信息。
                <br/>
                <br/>
               <h3> 6. 个人信息的国际转移</h3>

                为了促进我们的全球运营，UpWallet可能会将您的个人信息转移到欧洲经济区（“EEA”）、英国和瑞士以外的地方。EEA 包括欧盟国家以及冰岛、列支敦士登和挪威。EEA 以外的转移有时被称为“第三国转移”。
                <br/>
                我们可能会将您的个人信息传输给我们位于世界各地的关联公司、第三方合作伙伴和服务提供商。如果我们打算将个人信息传输到欧洲经济区以外的第三国或国际组织，UpWallet将采取适当的技术、组织和合同保障措施（包括标准合同条款），以确保此类传输符合适用的数据保护规则。
                <br/>
                我们还依赖欧盟委员会的决定，他们承认欧洲经济区以外的某些国家和地区确保对个人信息提供充分的保护。这些决定被称为“充分性决定”。
                <br/>
                <br/>
               <h3> 7. 我的信息有多安全？</h3>

                我们在设计系统时充分考虑了您的安全和隐私。我们已采取适当的安全措施，以防止您的信息意外丢失、以未经授权的方式被使用或访问、被更改或泄露。我们努力通过使用加密协议和软件来保护您的个人数据在传输和存储过程中的安全。我们在收集、存储和披露您的个人数据方面采取物理、电子和程序保障措施。此外，我们限制您的个人数据的访问权限给那些有业务需要了解的员工、代理、承包商和其他第三方。
                <br/>
                我们的安全程序意味着我们可能会要求您验证您的身份，以防止他人未经授权访问您的帐户。我们建议您为您的UpWallet帐户使用一个独特的密码，该密码不用于其他在线帐户，并且在您使用完共享计算机后退出。
                <br/>
                <br/>
              <h3>  8. 广告怎么样？</h3>

                为了向您提供最佳的用户体验，我们可能会与我们的营销合作伙伴共享您的个人数据，用于定位、建模和/或分析以及营销和广告。您有权随时反对将您的个人数据用于直接营销目的（请参阅下文第 10 节）。
<br/>
                <br/>
              <h3>  9.我有哪些权利？</h3>

                根据适用法律，如下所述，您在隐私和个人数据保护方面拥有多项权利。您有权请求访问、更正和删除您的个人数据，并要求数据可携性。您还可以反对我们处理您的个人数据，或要求我们在某些情况下限制对您的个人数据的处理。此外，当您同意我们为特定目的处理您的个人数据时，您可以随时撤回您的同意。如果您想行使您的任何权利，请使用此处提供的网络表格与我们联系。这些权利在某些情况下可能会受到限制 - 例如，当我们可以证明我们有法律义务处理您的个人数据时。
                <br/>
                访问权：您有权确认您的个人数据已被处理，并有权获得其副本以及与其处理相关的某些信息。请参阅常见问题解答，了解有关如何自行访问您的帐户报表的更多信息。您还可以通过此处的专用“帐户活动记录”页面检查您的设备和 IP 地址登录信息。请注意，您必须先登录您的帐户才能获得访问权限；
                <br/>
                更正权：您可以要求更正不正确的个人数据，也可以补充。您还可以随时更改帐户中的个人数据。
                <br/>
                删除权：在某些情况下，您可以删除您的个人数据。请参阅常见问题解答，获取有关如何自行请求删除帐户的更多自助信息。请注意，在删除帐户的情况下，个人数据仍可能被保留，以履行我们的法律义务。
                <br/>
                反对权：您可以出于与您的情况有关的原因，反对我们处理您的个人数据，例如，当我们依赖合法利益或为直接营销目的处理您的数据时，您有权反对；
                <br/>
                限制处理的权利：在某些情况下，您有权暂时限制我们处理您的个人数据，前提是有正当理由。如果出于辩护合法索赔的需要或适用法律允许的任何其他例外情况，我们可能会继续处理您的个人数据；
                <br/>
                对仅基于自动化处理的决定提出异议的权利：如果决定完全通过自动化手段做出，没有人工参与，您有权要求重新考虑这些决定；我们使用自动化工具来确保您有资格成为我们的客户，同时考虑到我们的利益和法律义务；如果这些自动化工具表明您不符合我们的接受标准，我们将不会接纳您作为我们的客户；
                <br/>
                可携带权：在某些情况下，您可以要求以结构化、常用和机器可读的格式接收您提供给我们的个人数据，或者，在可能的情况下，我们代表您将您的个人数据直接传达给另一个数据控制者；
                <br/>
                撤回同意的权利：对于需要您同意的处理，您有权随时撤回您的同意。行使此权利不会影响基于撤回前同意而进行的处理的合法性；
                <br/>
                向相关数据保护机构投诉的权利：我们希望能够解答您对我们处理个人数据方式的任何疑问。但是，如果您有未解决的疑虑，您也有权向您居住地、工作地或认为发生了数据保护违规行为所在地的数据保护机构投诉。
                <br/>
                如果您对我们收集和处理您的个人数据的方式有任何疑问或异议，请使用此处提供的网络表格与我们联系。
                <br/>
                <br/>
               <h3> 10.UpWallet将保留我的个人数据多长时间？</h3>

                我们保留您的个人数据，以使您能够继续使用UpWallet服务，只要这是为了实现本隐私声明中描述的相关目的所必需的，并且是为了法律可能要求的，例如出于税务和会计目的、遵守反洗钱法、或解决争议和/或法律索赔，或以其他方式传达给您。
                <br/>
                虽然保留要求因司法管辖区而异，但我们对您个人数据不同方面的典型保留期限信息如下所述。
                <br/>
                为了履行我们在金融或反洗钱法律下的法律义务而收集的个人身份信息可能会在账户关闭后根据这些法律的要求保留一段时间。
                <br/>
                我们将持续保留您的姓名、电子邮件地址和电话号码等联系信息，以用于营销目的，直至您 (a) 取消订阅，或我们 (b) 删除您的帐户。此后，我们会将您的详细信息添加到取消订阅列表中，以确保我们不会无意中向您推销。
                <br/>
                您在我们的网站上发布的内容（例如支持台评论、照片、视频、博客帖子和其他内容）可能会在您关闭帐户后保留，以用于审计和预防犯罪的目的。
                <br/>
                您的语音通话记录可能会被保存长达六年，用于审计/合规目的以及解决争议和/或法律索赔。
                <br/>
                通过 cookies、网页计数器和其他分析工具收集的信息将自相关 cookie 收集之日起保留长达一年。
                <br/>
                <br/>
               <h3> 11. 通知和修订</h3>

                如果您对UpWallet的隐私有任何疑虑，请联系我们，我们将尽力解决。您也有权联系您当地的数据保护机构。
  <br/>
                我们的业务经常变化，我们的隐私声明也可能变化。您应经常查看我们的网站以了解最新变化。我们将在本隐私声明开头相应地更新“上次更新”日期。我们将在我们的平台上或通过您在账户下提供的电子邮件宣布本隐私声明的任何重大变更。在本隐私声明变更后，您继续使用UpWallet即表示您理解并同意此类变更。
                <br/>
                除非另有说明，我们最新的隐私声明适用于我们掌握的有关您和您的帐户的所有信息。
                <br/>
                <br/>
               <h3> 12. 语言</h3>

                本隐私政策可能以多种语言发布。如有任何差异，以英文版本为准。
                <br/>
                <br/>
              <h3>  13.联系信息</h3>

                您可以使用下面提供的网络表格或通过电子邮件联系我们的数据保护官，他将努力解决您在收集和处理您的个人数据方面遇到的任何问题或问题。
                <br/>
                如果您对账户管理或UpWallet产品或服务有疑问，数据保护官将无法为您提供帮助。在这种情况下，请使用以下链接联系我们的客户支持团队。
                <br/>
                请阅读常见问题解答以获取有关如何删除帐户的更多信息。
                <br/>
            </div>
        </Content>
    )
}

const Content = styled.div`
  display: flex;
  
`
const IntroSection = styled.div`
    margin-bottom: 0px;
    padding-bottom: 3rem;
    text-align: left;
`
